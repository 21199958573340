<template>
	<div class="clients">
		<div class="flx gap-2">
			<input type="search" v-model="search" placeholder="Filter Clients..." class="search flx-1 ba" />
			<vSelect
				v-model="selectedUser"
				@search="(v) => (userSearch = v)"
				:options="userList"
				label="label"
				placeholder="Filter by User..."
				class="flx-1 ba"
			>
				<template #option="option">
					<span
						>{{ option.lastname }}, {{ option.firstname }}
						<span class="optEmail">{{ option.email }}</span>
					</span>
				</template>
				<template #selected-option="{ firstname, lastname }">{{ lastname }}, {{ firstname }}</template>
			</vSelect>
			<vSelect
				v-model="selectedOrg"
				@search="(v) => (orgSearch = v)"
				:options="orgList"
				label="title"
				placeholder="Filter by Organization..."
				class="flx-1 ba"
			/>
			<Pagination :totalPages="totalPages" :page="paged" @click="setPage" class="flx-0" />
		</div>
		<DataTable :items="clientList" class="bh bt mt-5">
			<template v-slot:thead>
				<tr>
					<th>id</th>
					<th class="stickLeft">Clients (Total: {{ total }})</th>
					<th>Portal Key</th>
					<th>Last Login</th>
					<th>Total Logins</th>
					<th>Clinician</th>
					<th>Organization</th>
					<th>Created</th>
				</tr>
			</template>
			<template v-slot:tbody="{ item }">
				<tr @click="(e) => showContextMenu(item.id, e)" :class="{ selected: selectedClient.id === item.id }">
					<td class="ts" @click.stop>
						<div class="flx jst-cntr">
							<Spinner v-if="updating === item.id" />
							<template v-else>{{ item.id }}</template>
						</div>
					</td>
					<td class="stickLeft">
						<div class="name">
							<span>{{ item.lastname }}, {{ item.firstname }}</span>
							<template v-if="item.username"> &mdash; {{ item.username }}</template>
						</div>
						<div>{{ item.email }}</div>
						<div v-if="item.premium" class="tag premium bgGrdWarn">Client Premium</div>
					</td>
					<td>{{ item.portalKey }}</td>
					<td class="ts">{{ item.lastLogin ? item.lastLogin.split("T")[0] : "-" }}</td>
					<td>{{ item.cntLogins }}</td>
					<td @click.stop="setUserId(item.userId)" class="clinicianCol">
						<div class="title">
							{{ item.clinicianLastname }}, {{ item.clinicianFirstname }}
							<span
								@click.stop="router.push({ name: 'Users', params: { id: item.userId } })"
								class="emoji"
								>😑</span
							>
						</div>
						<div>{{ item.clinicianEmail }}</div>
					</td>
					<td @click.stop="setOrgId(item.orgId)" class="orgCol">
						<div v-if="item.orgId" class="title">
							<span v-if="item.orgTitle" class="mr-4">{{ item.orgTitle }}</span>
							<span
								@click.stop="router.push({ name: 'Organizations', params: { id: item.orgId } })"
								class="emoji"
								>🏢</span
							>
						</div>
						<div v-for="(team, i) in item.teams" :key="i" class="team">{{ team.title }}</div>
					</td>
					<td class="ts">{{ item.created.split("T")[0] }}</td>
				</tr>
			</template>
			<template v-slot:noResults> No results </template>
			<template v-slot:tfoot>
				<div class="flx" v-if="clientId">
					<button class="ml-a mr-a mt-5 ph-6 bgGrdAcc" @click="backToList">Show all Clients...</button>
				</div>
			</template>
		</DataTable>

		<Transition name="slideDown">
			<ContextMenu v-if="contextMenu" :pos="contextMenu" @close="contextMenu = false">
				<header>{{ selectedClient.firstname }} {{ selectedClient.lastname }}</header>
				<button @click="showEdit(selectedClient.id)">Edit</button>
				<button @click="loginAs(selectedClient.id)">Login As</button>
				<button @click="changeUser(selectedClient)">Change Clinician</button>
				<button v-if="!selectedClient.premium" @click="updateAttr(selectedClient.id, 'premium', true)">
					Set Premium
				</button>
				<button v-else @click="updateAttr(selectedClient.id, 'premium', false)">Unset Premium</button>
				<button @click="delClient(selectedClient)">Delete</button>
			</ContextMenu>
		</Transition>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch, onMounted } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import { api, alertsList, modals, clients, users, orgs } from "@/store"
	import { ContextMenu, DataTable, Pagination, Spinner } from "@/components"
	import vSelect from "vue-select"
	import ChooseUser from "../ChooseUser"
	import EditClient from "./EditClient"

	export default {
		name: "ClientList",
		components: { ContextMenu, DataTable, Pagination, Spinner, vSelect },
		props: {},
		setup(props) {
			const router = useRouter()
			const route = useRoute()
			const modalStore = modals()

			// clientList
			const clientStore = clients()
			const total = ref(0)
			const page = computed(() => +route.query.page || 1)
			const paged = ref(1)
			const perPage = ref(50)
			const totalPages = computed(() => Math.ceil(total.value / perPage.value))
			const clientIds = ref([])
			const clientList = computed(() =>
				clientIds.value.map((id) => clientStore.clients.value[id]).filter((v) => v)
			)
			const clientId = computed(() => +route.params.id || null)
			const search = ref("")
			const searched = ref("")
			const selectedClient = ref({})
			const setPage = (v) => router.push({ query: { page: v, userId: userId.value }, name: "Clients" })
			const fetchClients = async (p) => {
				if (clientId.value) {
					findClientById(clientId.value)
				} else {
					const filter = { page: p || page.value }
					if (search.value) filter.search = search.value
					if (userId.value) filter.userId = userId.value
					else if (orgId.value) filter.orgId = orgId.value
					const result = await clientStore.fetchClients(filter)
					if (result) {
						searched.value = result.search
						total.value = result.total
						clientIds.value = result.ids
						paged.value = result.page
						if (page.value !== paged.value) setPage(paged.value)
					}
				}
			}
			const debouncedFetchClients = debounce(fetchClients, 500)
			const findClientById = async (clientId) => {
				if (!clientStore.clients.value[clientId]) await clientStore.fetchClients(clientId)
				clientIds.value = [clientId]
				selectedClient.value = clientStore.clients.value[clientId]
			}
			const backToList = () => {
				fetchClients(1)
				router.push({ name: "Clients" })
			}
			watch(search, () => debouncedFetchClients(1))
			watch(page, () => {
				if (page.value !== paged.value) {
					fetchClients()
				}
			})
			watch(clientId, (v) => {
				if (v) findClientById(v)
				else fetchClients()
			})

			// userFilter
			const userId = computed(() => route.query.userId)
			const setUserId = (v) => {
				search.value = ""
				router.push({ query: { page: 1, userId: v, orgId: undefined }, name: "Clients" })
			}
			const userStore = users()
			const userSearch = ref("")
			const selectedUser = computed({
				get() {
					if (userId.value && !userStore.users.value[userId.value]) userStore.fetchUser(userId.value)
					const o = userStore.users.value[userId.value]
					return typeof o === "object" ? o : undefined
				},
				set(v) {
					setUserId(v ? v.id : undefined)
				}
			})
			const userIds = ref([])
			const userList = computed(() => userIds.value.map((id) => userStore.users.value[id]))
			const fetchUsers = debounce(async (search) => {
				const result = await userStore.fetchUsers({ search: search || "" })
				if (result) userIds.value = result.ids
			}, 500)
			watch(userSearch, fetchUsers)
			watch(selectedUser, () => fetchClients())

			// orgFilter
			const orgId = computed(() => route.query.orgId)
			const setOrgId = (v) => {
				search.value = ""
				router.push({ query: { page: 1, orgId: v, userId: undefined }, name: "Clients" })
			}
			const orgStore = orgs()
			const orgSearch = ref("")
			const selectedOrg = computed({
				get() {
					if (orgId.value && !orgStore.orgs.value[orgId.value]) orgStore.fetchOrg(orgId.value)
					const o = orgStore.orgs.value[orgId.value]
					return typeof o === "object" ? o : undefined
				},
				set(v) {
					setOrgId(v ? v.id : undefined)
				}
			})
			const orgIds = ref([])
			const orgList = computed(() => orgIds.value.map((id) => orgStore.orgs.value[id]))
			const fetchOrgs = debounce(async (search) => {
				const result = await orgStore.fetchOrgs({ search: search || "" })
				if (result) orgIds.value = result.ids
			}, 500)
			watch(orgSearch, fetchOrgs)
			watch(selectedOrg, () => fetchClients())

			// contextMenu
			const contextMenu = ref(false)
			const showContextMenu = (clientId, { clientX, clientY }) => {
				selectedClient.value = clientStore.clients.value[clientId]
				contextMenu.value = { x: clientX, y: clientY }
			}

			// actions
			const alerts = alertsList()
			const showEdit = (clientId) => {
				modalStore.open.component({
					component: EditClient,
					props: { id: clientId },
					size: "sm"
				})
			}
			const updateAttr = (clientId, attr, val) =>
				clientStore.updateClient(clientId, { [attr]: val }).then((result) => {
					alerts.push("Done", "pos")
					return result
				})
			const loginAs = async (clientId) => {
				const result = await api.post(`admin/clients/${clientId}/login`)
				if (result) window.open(`https://${window.location.hostname.replace("admin.", "client.")}`)
			}
			const changeUser = async (client) => {
				const user = await modalStore.open.component({
					component: ChooseUser,
					size: "sm"
				})
				if (user && user.id !== client.userId && user.clinician) {
					const confirm = await modalStore.open.dialog({
						header: "Confirm Change User",
						body: `
							You are about to assign the following Client to the following User:<br /><br />
							Client: <b>${client.firstname} ${client.lastname} &mdash; ${client.email}</b><br />
							User: <b>${user.firstname} ${user.lastname} &mdash; ${user.email}</b><br /><br />
							<b>Note:</b><br />
							If new User is outside of Client Organization, Client will be unjoined from all Teams
						`,
						buttons: [
							{
								label: "Cancel"
							},
							{
								label: "Confirm"
							}
						]
					})
					if (confirm) {
						clientStore.updateClient(client.id, { userId: user.id }).then((result) => {
							alerts.push("Done", "pos")
						})
					}
				}
			}
			const delClient = async (client) => {
				const confirm = await modalStore.open.dialog({
					header: "Delete Client",
					body: `
						You are about to delete the following Client:<br /><br />
						User: <b>${client.firstname} ${client.lastname} &mdash; ${client.email}</b><br /><br />
						<b>Are you sure?</b><br />
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (!confirm) return
				const confirm2 = await modalStore.open.dialog({
					header: "With great power...",
					body: `
						User: <b>${client.firstname} ${client.lastname} &mdash; ${client.email}</b><br /><br />
						Are you really, really sure?
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (!confirm2) return
				const confirm3 = await modalStore.open.dialog({
					header: "One last check to be sure",
					body: `
						User: <b>${client.firstname} ${client.lastname} &mdash; ${client.email}</b><br /><br />
						There's no coming back from this...
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "DELETE"
						}
					]
				})
				if (confirm3) {
					const clientId = await clientStore.deleteClient(client.id)
					if (clientId) {
						clientIds.value = clientIds.value.filter((id) => id !== clientId)
						alerts.push("Deleted", "pos")
					}
				}
			}

			onMounted(() => {
				fetchUsers()
				fetchOrgs()
				if (route.params.id) findClientById(route.params.id)
				else fetchClients()
			})

			return {
				router,
				...clientStore,
				total,
				page,
				paged,
				perPage,
				totalPages,
				clientId,
				clientList,
				search,
				searched,
				selectedClient,
				fetchClients,
				setPage,
				setUserId,
				setOrgId,
				backToList,

				selectedUser,
				userSearch,
				userList,

				selectedOrg,
				orgSearch,
				orgList,

				contextMenu,
				showContextMenu,
				loginAs,
				changeUser,
				delClient,
				updateAttr,

				showEdit
			}
		}
	}
</script>

<style lang="scss">
	.clients {
		display: grid;
		grid-template-rows: auto 1fr;
		.dataTable {
			th.stickLeft:before {
				content: "";
				display: block;
				width: 200px;
			}
			tr.disabled {
				div,
				td,
				span {
					color: $clr-neg !important;
					background: $clr-neut-l2 !important;
				}
			}
			.spinner {
				width: 18px;
				height: 18px;
			}
			.title {
				color: $clr-prim;
				font-weight: bold;
			}
			.name > span {
				color: $clr-prim;
				font-weight: bold;
			}
			.team {
				color: $clr-dark;
			}
			.clinicianCol {
				cursor: zoom-in;
			}
			.orgCol {
				cursor: zoom-in;
			}
			.emoji {
				cursor: pointer;
				font-size: 16px;
			}
			.premium {
				display: inline-block;
			}
			/*
			.orgCol {
				cursor: pointer;
				&:hover .orgTitle {
					text-decoration: underline;
				}
			}
			*/
		}
	}
</style>
