<template>
	<div class="header">{{ client.firstname }} {{ client.lastname }}</div>
	<div class="body">
		<div class="fields">
			<div>Username</div>
			<div><input type="text" v-model="client.username" placeholder="[null]" /></div>
			<div>Email</div>
			<div><input type="text" v-model="client.email" /></div>
			<div>Firstname</div>
			<div><input type="text" v-model="client.firstname" /></div>
			<div>Lastname</div>
			<div><input type="text" v-model="client.lastname" /></div>
			<div>Portal Key</div>
			<div><input type="number" v-model="client.portalKey" /></div>
		</div>
	</div>
	<div class="footer">
		<button @click="close">Close</button>
		<Richbutton :theme="canSave ? 'prim' : ''" :working="updating" :disabled="!canSave" @click="save">
			Save
		</Richbutton>
	</div>
</template>

<script>
	import { cloneDeep, pick } from "lodash"
	import { ref, computed, onMounted } from "vue"
	import { alertsList, clients } from "@/store"
	import { Richbutton } from "@/components"

	export default {
		name: "EditClient",
		components: { Richbutton },
		props: {
			id: { type: Number, required: true }
		},
		emits: ["close"],
		setup(props, cntxt) {
			const alerts = alertsList()
			const clientStore = clients()
			const client = ref({})
			const saved = computed(() => clientStore.clients.value[props.id])
			const canSave = computed(() => JSON.stringify(client.value) !== JSON.stringify(saved.value))
			const close = (result) => cntxt.emit("close", result)
			const save = async () => {
				const payload = {
					...pick(client.value, ["firstname", "lastname", "email", "portalKey"]),
					username: client.value.username || null
				}
				payload.portalKey = payload.portalKey += ""
				try {
					const result = await clientStore.updateClient(client.value.id, payload)
					alerts.push("Saved", "pos")
					close(result)
				} catch (err) {
					client.value = cloneDeep(saved.value)
				}
			}

			onMounted(() => {
				client.value = cloneDeep(saved.value)
			})

			return {
				...clientStore,
				client,
				save,
				canSave,
				close
			}
		}
	}
</script>

<style lang="scss"></style>
